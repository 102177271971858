<template>
  <div class="py-4 bg-expandable" @mousewheel="closeActions">
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" start-space="35px" />
    <Table
      v-if="activeTab === 0"
      v-loading="loading"
      :columns="orderColumns"
      :data="orderData"
      custom-class="activity-table"
      show-index
      small
      @row-click="handleRowClick"
    >
      <template #cell-date="{ rowData: { date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
      <template #cell-documents="{ rowData: { documents } }">
        <template v-if="documents && documents.length">
          <Button
            v-for="doc in documents"
            :key="doc.id"
            type="link"
            class="p-0 m-0"
            @click.stop="handleOpenDocument({ documentId: doc.id, documentIds: getDocumentsIds(orders) })"
          >
            {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
          </Button>
        </template>
        <span v-else>{{ $t('payment.paymentTable.expandableActivity.balanceOrder') }}</span>
      </template>
      <template #cell-netAmount="{ rowData: { netAmount, hasDifferences, differences } }">
        <p
          :class="{
            ['text-warning']: hasDifferences && !(isOrderDifferenceSolved(differences) === 'all'),
            ['text-success']: isOrderDifferenceSolved(differences) === 'all',
          }"
        >
          {{ netAmount }}
        </p>
      </template>
      <template #cell-details="{ rowData: { details, differences } }">
        <div>
          <span v-if="isOrderDifferenceSolved(differences) === 'all'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifference') }}
            </span>
          </span>
          <span v-if="isOrderDifferenceSolved(differences) === 'partial'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifferencePartial') }}
            </span>
          </span>
          <span :class="{ 'text-decoration-line-through': isOrderDifferenceSolved(differences) === 'all' }">
            {{ details }}
          </span>
        </div>
      </template>
    </Table>
    <Table
      v-if="activeTab === 1"
      v-loading="loading"
      :columns="deliveryColumns"
      :data="deliveryData"
      custom-class="activity-table"
      show-index
      small
      @row-click="handleRowClick"
    >
      <template #cell-date="{ rowData: { date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
      <template #cell-documents="{ rowData: { documents } }">
        <template v-if="documents && documents[0].id">
          <Button
            v-for="doc in documents"
            :key="doc.id"
            type="link"
            class="p-0 m-0"
            @click.stop="handleOpenDocument({ documentId: doc.id, documentIds: getDocumentsIds(deliveries) })"
          >
            {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
          </Button>
        </template>
        <p v-else class="text-warning">{{ getMissingType(documents) }}</p>
      </template>
      <template #cell-productCount="{ rowData: { productCount, hasDifferences, hasImbalances, diffs } }">
        <p
          :class="{
            ['text-warning']: hasDifferences && !(isDeliveryDifferenceSolved(diffs) === 'all'),
            ['text-danger']: hasImbalances,
            ['text-success']: isDeliveryDifferenceSolved(diffs) === 'all',
          }"
        >
          {{ productCount }}
        </p>
      </template>
      <template #cell-details="{ rowData: { details, parentDocument, diffs, hasDifferences } }">
        <div>
          <span v-if="isDeliveryDifferenceSolved(diffs) === 'all'">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifference') }}
            </span>
          </span>
          <span v-if="hasDifferences && !(isDeliveryDifferenceSolved(diffs) === 'all')">
            <span>
              {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifferencePartial') }}
            </span>
          </span>
          <span :class="{ 'text-decoration-line-through': isDeliveryDifferenceSolved(diffs) === 'all' }">
            {{ details }}
          </span>
        </div>
        <template v-if="parentDocument">
          (
          <Button
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="handleOpenDocument({ documentId: parentDocument.id })"
          >
            {{ getParentDocumentTextWithContext(parentDocument) }}
          </Button>
          )
        </template>
      </template>
      <template #cell-actions="{ rowData: { missingEventId }, rowIndex }">
        <el-dropdown
          v-if="isAdmin && missingEventId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="handleAction($event, missingEventId)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="icon"
            class="p-0 action-button"
            :class="{ active: activeActions === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item v-if="isAdmin && missingEventId" :command="ACTION.RESOLVE_MISSING_EVENT">
              {{ $t('payment.paymentTable.expandableActivity.actions.resolveMissingEvent') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <Table
      v-if="activeTab === 2"
      v-loading="loading"
      :columns="billingColumns"
      :data="billingData"
      custom-class="activity-table"
      show-index
      small
      @row-click="handleRowClick"
    >
      <template #cell-date="{ rowData: { date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
      <template #cell-documents="{ rowData: { type, documents } }">
        <span v-if="type === 'balance'">{{ $t('payment.paymentTable.expandableActivity.balanceBilling') }}</span>
        <template v-else-if="documents && documents[0].id">
          <Button
            v-for="doc in documents"
            :key="doc.id"
            type="link"
            class="p-0"
            @click.stop="handleOpenDocument({ documentId: doc.id, documentIds: getDocumentsIds(billings) })"
          >
            {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
          </Button>
        </template>
        <p v-else class="text-warning">{{ getMissingType(documents) }}</p>
      </template>
      <template #cell-status="{ rowData: { status } }">
        <Status :status="status" />
      </template>
      <template
        #cell-details="{
          rowData: {
            id,
            parentDocument,
            imbalances,
            isAllImbalancesResolved,
            hasRelatedBillings,
            supplierId,
            date,
            status,
            documents,
          },
        }"
      >
        <template
          v-if="$listeners['open-issues'] && imbalances.length && status !== RECONCILIATION_STATUSES.WAITING_FOR_MATCH"
        >
          <p v-if="isAllImbalancesResolved">
            {{ `[${$t('payment.paymentTable.expandableActivity.details.billing.imbalanceResolved')}]` }}
            <span class="text-decoration-line-through">{{
              $tc('payment.paymentTable.details.imbalance', imbalances.length)
            }}</span>
          </p>
          <Button
            v-else
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="$emit('open-issues', { id, supplierId, date })"
          >
            <span v-if="hasRelatedBillings">{{
              `[${$t('payment.paymentTable.expandableActivity.details.billing.imbalanceResolvedPartially')}]`
            }}</span>
            {{ $tc('payment.paymentTable.details.imbalance', imbalances.length) }}
          </Button>
        </template>
        <template
          v-else-if="
            $listeners['open-credit-match'] &&
            imbalances.length &&
            !isAllImbalancesResolved &&
            status === RECONCILIATION_STATUSES.WAITING_FOR_MATCH
          "
        >
          <Button
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="$emit('open-credit-match', { billingId: id, supplierId, date })"
          >
            {{ $t('payment.paymentTable.details.notMatched') }}
          </Button>
        </template>
        <template v-else-if="parentDocument">
          (
          <Button
            type="link"
            class="p-0 text-typography-primary"
            @click.stop="handleOpenDocument({ documentId: parentDocument.id })"
          >
            {{ getParentDocumentText(parentDocument) }}
          </Button>
          )
        </template>
        <template v-else-if="documents && !documents[0].id">
          {{ $t('payment.paymentTable.expandableActivity.details.missing') }}
        </template>
      </template>
      <template #cell-actions="{ rowData: { missingEventId }, rowIndex }">
        <el-dropdown
          v-if="isAdmin && missingEventId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="handleAction($event, missingEventId)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="icon"
            class="p-0 action-button"
            :class="{ active: activeActions === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item v-if="isAdmin && missingEventId" :command="ACTION.RESOLVE_MISSING_EVENT">
              {{ $t('payment.paymentTable.expandableActivity.actions.closeTask') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <Table
      v-if="activeTab === 3"
      v-loading="loading"
      :columns="paymentColumns"
      :data="paymentData"
      small
      custom-class="activity-table"
      show-index
      @row-click="handleRowClick"
    >
      <template #[`cell-${PAYMENT_TABLE_HEADER.DATE}`]="{ rowData: { [PAYMENT_TABLE_HEADER.DATE]: date } }">
        <p>{{ formatDate(date) }}</p>
      </template>
    </Table>
    <DocumentModal
      v-if="displayedDocument.documentId"
      visible
      :document-id="displayedDocument.documentId"
      @close="displayedDocument = {}"
    >
      <template v-if="displayedDocument.documentIds && displayedDocument.documentIds.length" #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="displayedDocument.documentIds.length"
          :current-page="displayedDocument.index + 1"
          :pager-count="5"
          @update:currentPage="handleDocumentModalPagination"
        />
      </template>
    </DocumentModal>
    <EventMapModal v-if="activity" :activity="activity" @close="activity = null" />
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { isNil, flatten, uniq, reject, pipe } from 'ramda';
import { DateTime } from 'luxon';

import { Tabs, Table, Button } from '@/modules/core';
import { DOCUMENT_TYPES, NOTE_DOCS, CONSOLIDATED_DOCS } from '@/modules/document/types';
import { options } from '@/locale/dateConfig';
import { NoticeIcon, KebabIcon } from '@/assets/icons';
import { useOrderProductPatch } from '@/modules/order';
import { useDeliveryItemPatch } from '@/modules/delivery';
import { DocumentModal } from '@/modules/documentModal';
import { useTenancy } from '@/modules/auth';
import { RECONCILIATION_STATUSES, usePatchMissingEvent } from '@/modules/reconciliation';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useEvents, useActivityDocuments } from '../../compositions';

const dateSort = (a, b) => new Date(a.date) - new Date(b.date);

const ACTION = {
  RESOLVE_MISSING_EVENT: 'resolveMissingEvent',
};

const ORDER_TABLE_HEADER = {
  DATE: 'date',
  DOCUMENTS: 'documents',
  PRODUCT_COUNT: 'productCount',
  TOTAL_NET_AMOUNT: 'netAmount',
  DETAILS: 'details',
};

const DELIVERY_TABLE_HEADER = {
  DATE: 'date',
  DOCUMENTS: 'documents',
  PRODUCT_COUNT: 'productCount',
  DETAILS: 'details',
};

const BILLING_TABLE_HEADER = {
  DATE: 'date',
  DOCUMENTS: 'documents',
  TOTAL_NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
  DETAILS: 'details',
};

const PAYMENT_TABLE_HEADER = {
  DATE: 'date',
  REFERENCE: 'reference',
  AMOUNT: 'amount',
};

const TABS = {
  ORDER: 'order',
  DELIVERY: 'delivery',
  BILLING: 'billing',
  PAYMENT: 'payment',
};

const COLUMNS_WIDTH = '140px';
const COLUMNS_DOCUMENT_NUMBER_WIDTH = '220px';
const COLUMNS_DATE_WIDTH = '90px';

const getDocumentsIds = (events) => {
  const eventsClone = [...events];
  eventsClone.sort(dateSort);
  return flatten(eventsClone.map((event) => event.eventReferences.map(({ documentId }) => documentId)));
};

export default {
  components: {
    Tabs,
    Table,
    Button,
    KebabIcon,
    DocumentModal,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
    Status: () => import('@/modules/reconciliation/reconciliationStatus/Status'),
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    reconciliationsStatus: { type: Object, default: null },
    missingLoading: { type: Boolean, default: false },
    billingIds: { type: Array, default: () => [] },
    paymentIds: { type: Array, default: () => [] },
    deliveryIds: { type: Array, default: () => [] },
    orderIds: { type: Array, default: () => [] },
    missingEvents: { type: Array, default: () => [] },
  },
  setup(props) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    const {
      events,
      refetch,
      loading: eventsLoading,
    } = useEvents(
      computed(() => props.billingIds),
      computed(() => props.paymentIds),
      computed(() => props.orderIds),
      computed(() => props.deliveryIds)
    );
    const { onDone: onOrderChange } = useOrderProductPatch();
    const { onDone: onDeliveryChange } = useDeliveryItemPatch();

    onOrderChange(refetch);
    onDeliveryChange(refetch);

    const { documents, loading: documentsLoading } = useActivityDocuments(
      computed(() => ({
        ids: pipe(flatten, reject(isNil), uniq)(Object.values(events.value).map(getDocumentsIds)),
        businessId: currentTenant.value.id,
      }))
    );

    const {
      patchMissingEvent,
      loading: patchMissingEventInProgress,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();

    patchMissingEventOnDone(() =>
      $message.success($i18n.t('payment.paymentTable.expandableActivity.messages.missingEventResolvedSuccess'))
    );
    patchMissingEventOnError(() =>
      $message.error($i18n.t('payment.paymentTable.expandableActivity.messages.missingEventResolvedError'))
    );

    return {
      formatToCurrency,
      formatCentsToCurrency,
      ACTION,
      RECONCILIATION_STATUSES,
      events,
      loading: computed(
        () => eventsLoading.value || documentsLoading.value || props.missingLoading || patchMissingEventInProgress.value
      ),
      documents,
      ORDER_TABLE_HEADER,
      DELIVERY_TABLE_HEADER,
      BILLING_TABLE_HEADER,
      PAYMENT_TABLE_HEADER,
      activeTab: ref(props.billingIds.length ? 2 : 0),
      displayedDocument: ref({}),
      activity: ref(null),
      activeActions: ref(null),
      patchMissingEvent,
      getDocumentsIds,
    };
  },
  computed: {
    tabs() {
      return Object.values(TABS).map((tabType) => {
        return {
          text: this.$t(`payment.paymentTable.expandableActivity.tab.${tabType}`),
          ...this.getTabIcon(tabType),
        };
      });
    },
    billings() {
      return this.events.billings.map(this.setDocumentsFromEventReferences);
    },
    deliveries() {
      return this.events.deliveries.map(this.setDocumentsFromEventReferences);
    },
    orders() {
      return this.events.orders.map(this.setDocumentsFromEventReferences);
    },
    missingDocs() {
      const billingsSortedByDate = [...this.billings].sort(dateSort);
      const fromDate = billingsSortedByDate[0]?.date;
      const toDate = billingsSortedByDate[billingsSortedByDate.length - 1]?.date;
      return this.missingEvents.filter((missingEvent) => {
        return (
          (new Date(missingEvent.estimatedDate) >= DateTime.fromJSDate(new Date(fromDate)).startOf('month') &&
            new Date(missingEvent.estimatedDate) <= DateTime.fromJSDate(new Date(toDate)).endOf('month')) ||
          billingsSortedByDate.some(
            (b) => missingEvent.parentDocumentId && missingEvent.parentDocumentId === b.eventReferences[0].documentId
          )
        );
      });
    },
    orderColumns() {
      return [
        {
          header: this.$t('payment.paymentTable.commonInnerTable.date'),
          key: ORDER_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.documentNumber'),
          key: ORDER_TABLE_HEADER.DOCUMENTS,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.productCount'),
          key: ORDER_TABLE_HEADER.PRODUCT_COUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.netAmount'),
          key: ORDER_TABLE_HEADER.TOTAL_NET_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.details'),
          key: ORDER_TABLE_HEADER.DETAILS,
        },
      ];
    },
    orderData() {
      const { DATE, DOCUMENTS, PRODUCT_COUNT, TOTAL_NET_AMOUNT, DETAILS } = ORDER_TABLE_HEADER;
      return this.loading
        ? []
        : this.orders
            .map((order) => ({
              id: order.id,
              [DATE]: order.date,
              [DOCUMENTS]: order.documents,
              [PRODUCT_COUNT]: order.products?.length ?? '-',
              [TOTAL_NET_AMOUNT]: this.formatMoneyShekels(order.netAmount),
              differences: order.differences,
              [DETAILS]: order.differences.length
                ? this.$tc(
                    'payment.paymentTable.expandableActivity.details.order.difference',
                    order.differences.length,
                    {
                      count: order.differences.length,
                      amount: this.formatMoneyShekels(
                        order.differences.reduce((total, diff) => total + diff.amount, 0)
                      ),
                    }
                  )
                : null,
              hasDifferences: Boolean(order.differences.length),
            }))
            .sort(dateSort);
    },
    deliveryColumns() {
      return [
        {
          header: this.$t('payment.paymentTable.commonInnerTable.date'),
          key: DELIVERY_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.documentNumber'),
          key: DELIVERY_TABLE_HEADER.DOCUMENTS,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.productCount'),
          key: DELIVERY_TABLE_HEADER.PRODUCT_COUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.details'),
          key: DELIVERY_TABLE_HEADER.DETAILS,
        },
        {
          key: 'actions',
          width: '40px',
        },
      ];
    },
    deliveryData() {
      const { DATE, DOCUMENTS, PRODUCT_COUNT, DETAILS } = DELIVERY_TABLE_HEADER;
      const missingDeliveries = this.missingDocs
        .filter(({ possibleTypes }) => possibleTypes.some((type) => NOTE_DOCS.includes(type)))
        .map((missingEvent) => ({
          missingEventId: missingEvent.id,
          date: missingEvent.context.issueDate,
          products: missingEvent.context.items,
          documents: [
            {
              type: missingEvent.possibleTypes,
              documentNumber: missingEvent.reference,
            },
          ],
          parentDocument: missingEvent.parentDocument,
          diffs: [],
          imbalances: [],
        }));
      return this.loading
        ? []
        : [...this.deliveries, ...missingDeliveries]
            .map((delivery) => {
              let details = null;

              if (!delivery.documents[0].id)
                details = this.$t('payment.paymentTable.expandableActivity.details.missing');

              if (delivery.documents[0].id && delivery.diffs.length)
                details = this.$tc(
                  'payment.paymentTable.expandableActivity.details.delivery.difference',
                  delivery.diffs.length,
                  {
                    count: delivery.diffs.length,
                  }
                );

              return {
                id: delivery.id,
                missingEventId: delivery.missingEventId,
                [DATE]: delivery.date,
                [DOCUMENTS]: delivery.documents,
                [PRODUCT_COUNT]: delivery.products?.length ?? '-',
                [DETAILS]: details,
                hasDifferences: Boolean(delivery.diffs.length),
                diffs: delivery.diffs,
                hasImbalances: Boolean(delivery.imbalances.length),
                parentDocument: delivery.parentDocument,
              };
            })
            .sort(dateSort);
    },
    billingColumns() {
      return [
        {
          header: this.$t('payment.paymentTable.commonInnerTable.date'),
          key: BILLING_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.documentNumber'),
          key: BILLING_TABLE_HEADER.DOCUMENTS,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.netAmount'),
          key: BILLING_TABLE_HEADER.TOTAL_NET_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.taxAmount'),
          key: BILLING_TABLE_HEADER.TAX_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.totalAmount'),
          key: BILLING_TABLE_HEADER.TOTAL_AMOUNT,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.status'),
          key: BILLING_TABLE_HEADER.STATUS,
          width: COLUMNS_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.details'),
          key: BILLING_TABLE_HEADER.DETAILS,
        },
        {
          key: 'actions',
          width: '40px',
        },
      ];
    },
    billingData() {
      const { DATE, DOCUMENTS, TOTAL_NET_AMOUNT, TAX_AMOUNT, TOTAL_AMOUNT, STATUS } = BILLING_TABLE_HEADER;
      const missingBillings = this.missingDocs
        .filter(({ possibleTypes }) =>
          possibleTypes.some((type) =>
            [...CONSOLIDATED_DOCS, DOCUMENT_TYPES.INVOICE, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(type)
          )
        )
        .map((missingEvent) => ({
          missingEventId: missingEvent.id,
          date: missingEvent.context.issueDate,
          products: missingEvent.context.items,
          documents: [
            {
              type: missingEvent.possibleTypes,
              documentNumber: missingEvent.reference,
            },
          ],
          parentDocument: missingEvent.parentDocument,
          diffs: [],
          imbalances: [],
        }));

      return this.loading
        ? []
        : [...this.billings, ...missingBillings]
            .map((billing) => {
              return {
                id: billing.id,
                missingEventId: billing.missingEventId,
                [DATE]: billing.date,
                [DOCUMENTS]: billing.documents,
                [TOTAL_NET_AMOUNT]: this.formatMoney(billing.netAmount),
                [TAX_AMOUNT]: this.formatMoney(billing.taxAmount),
                [TOTAL_AMOUNT]: this.formatMoney(billing.totalAmount),
                [STATUS]: this.getBillingStatus(billing),
                parentDocument: billing.parentDocument,
                imbalances: billing.imbalances,
                isAllImbalancesResolved:
                  billing.imbalances.length && billing.imbalances.every(({ resolved }) => resolved),
                hasRelatedBillings: this.getRelatedBillingIds(billing).length,
                supplierId: billing.supplierId,
                type: billing.type,
              };
            })
            .sort(dateSort);
    },
    paymentColumns() {
      return [
        {
          header: this.$t('payment.paymentTable.commonInnerTable.date'),
          key: PAYMENT_TABLE_HEADER.DATE,
          width: COLUMNS_DATE_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.documentNumber'),
          key: PAYMENT_TABLE_HEADER.REFERENCE,
          width: COLUMNS_DOCUMENT_NUMBER_WIDTH,
        },
        {
          header: this.$t('payment.paymentTable.commonInnerTable.amount'),
          key: PAYMENT_TABLE_HEADER.AMOUNT,
        },
      ];
    },
    paymentData() {
      const { DATE, REFERENCE, AMOUNT } = PAYMENT_TABLE_HEADER;
      return this.loading
        ? []
        : this.events.payments
            .map(({ id, date, requestedDate, transactions, amount, isRefund }) => ({
              id,
              [DATE]: date || requestedDate,
              [REFERENCE]: transactions[0] && this.getPaymentReferences(transactions[0]),
              [AMOUNT]: this.formatMoney(amount * (isRefund ? -1 : 1), false),
            }))
            .sort(dateSort);
    },
  },
  methods: {
    getBillingStatus(billing) {
      if (!billing.id) return RECONCILIATION_STATUSES.PENDING;
      const detailsObj = this.reconciliationsStatus?.details
        .find(({ key }) => key === 'billings')
        .details.find(({ key }) => key === billing.id);

      if (detailsObj) {
        return detailsObj.status;
      } else {
        if (billing.imbalances.every(({ resolved }) => resolved)) return RECONCILIATION_STATUSES.APPROVED;
        if (billing.netAmount < 0) return RECONCILIATION_STATUSES.WAITING_FOR_MATCH;
        return RECONCILIATION_STATUSES.NOT_APPROVED;
      }
    },
    getRelatedBillingIds(billing) {
      return billing
        ? uniq(flatten(billing.imbalances.map(({ billedAmounts }) => billedAmounts.map(({ id }) => id)))).filter(
            (id) => id !== billing.id
          )
        : [];
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, options.short) : '-';
    },
    formatMoney(value, divide = true) {
      if (divide) {
        return this.formatCentsToCurrency(value) ?? '-';
      }

      return this.formatToCurrency(value) ?? '-';
    },
    formatMoneyShekels(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    getPaymentReferences({ paymentMethod, reference }) {
      if (reference) return `${this.$t(`payment.exports.paymentMethods.fullName.${paymentMethod}`)} - ${reference}`;
      return this.$t(`payment.exports.paymentMethods.fullName.${paymentMethod}`);
    },
    getTabIcon(type) {
      if (this.loading) return null;

      const warringPrefix = {
        prefixIcon: NoticeIcon,
        prefixClass: 'text-warning',
      };
      const errorPrefix = {
        prefixIcon: NoticeIcon,
        prefixClass: 'text-danger',
      };
      const greyPrefix = {
        prefixIcon: NoticeIcon,
        prefixClass: 'text-grey',
      };

      const missingBillingsCount = this.missingDocs.filter(({ possibleTypes }) =>
        possibleTypes.some((type) => [...CONSOLIDATED_DOCS, DOCUMENT_TYPES.INVOICE].includes(type))
      ).length;

      const missingDeliveriesCount = this.missingDocs.filter(({ possibleTypes }) =>
        possibleTypes.some((type) => NOTE_DOCS.includes(type))
      ).length;

      switch (type) {
        case TABS.ORDER:
          return this.orders.some((order) => order.differences?.length) ? warringPrefix : null;
        case TABS.DELIVERY:
          if (this.deliveries.some((delivery) => delivery.imbalances.length)) return errorPrefix;
          if (this.deliveries.some((delivery) => delivery.diffs?.length) || missingDeliveriesCount > 0)
            return warringPrefix;
          return null;
        case TABS.BILLING: {
          const combinedBillingStatus = this.billings.map((b) => this.getBillingStatus(b));
          if (
            combinedBillingStatus?.some((status) => status === RECONCILIATION_STATUSES.PENDING) ||
            missingBillingsCount > 0
          ) {
            return greyPrefix;
          }
          if (combinedBillingStatus?.some((status) => status === RECONCILIATION_STATUSES.NOT_APPROVED)) {
            return errorPrefix;
          }
          return null;
        }
      }
    },
    getMissingType(documents) {
      if (!documents?.[0]?.type) return null;
      return `${documents[0].type
        .map((type) => this.$t(`document.exports.schema.type.shortName.${type}`))
        .join(' / ')} ${documents[0].documentNumber ? documents[0].documentNumber : ''}`;
    },
    getParentDocumentTextWithContext(parentDocument) {
      if (!parentDocument) return null;
      const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: '2-digit',
      });
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      const parentDocumentText = `${this.$t('commons.referencedWithin')}: ${documentTypeText} ${
        parentDocument.documentNumber
      }`;
      return `${parentDocumentText} - ${documentDateText}`;
    },
    getParentDocumentText(parentDocument) {
      if (!parentDocument) return null;
      const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: 'numeric',
      });
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      return `${this.$t('commons.reference')}: ${documentTypeText} ${documentDateText}`;
    },
    setDocumentsFromEventReferences(event) {
      const eventDocRefs = event.eventReferences.map(({ documentId }) => documentId);
      const documents = this.documents.filter(({ id }) => eventDocRefs.includes(id));
      if (documents.length)
        return {
          ...event,
          documents: documents.map((document) => ({
            id: document.id,
            type: document.type,
            documentNumber: document.documentNumber,
            issueDate: document.issueDate,
          })),
        };

      return event;
    },
    handleRowClick(index) {
      switch (this.activeTab) {
        case 0: {
          const id = this.orderData[index].id;
          if (id) this.activity = { id, type: 'order' };
          break;
        }
        case 1: {
          const id = this.deliveryData[index].id;
          if (id) this.activity = { id, type: 'delivery' };
          break;
        }
        case 2: {
          const id = this.billingData[index].id;
          if (id) this.activity = { id, type: 'billing' };
          break;
        }
        case 3: {
          const id = this.paymentData[index].id;
          if (id) this.activity = { id, type: 'payment' };
          break;
        }
        default:
          break;
      }
    },
    handleOpenDocument({ documentId, documentIds }) {
      this.displayedDocument = {
        documentId,
        documentIds,
        index: documentIds?.indexOf(documentId),
      };
    },
    handleDocumentModalPagination(page) {
      if (page - 1 === this.displayedDocument.index) return;
      const nextIndex = page - 1;
      this.displayedDocument = {
        ...this.displayedDocument,
        documentId: this.displayedDocument.documentIds[nextIndex],
        index: nextIndex,
      };
    },
    handleAction(command, missingEventId) {
      switch (command) {
        case ACTION.RESOLVE_MISSING_EVENT:
          this.patchMissingEvent({ id: missingEventId, data: { resolved: true } });
          break;
        default:
          break;
      }
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    closeActions() {
      if (this.activeActions !== null) {
        document.getElementById(`actions-row-${this.activeActions}`).click();
        this.activeActions = null;
      }
    },
    isDeliveryDifferenceSolved(differences) {
      if (differences.length === 0) return false;
      const unresolvedDifferences = differences?.filter((diff) => diff.resolved != true);
      const resolvedDifferences = differences?.filter((diff) => diff.resolved === true);
      if (unresolvedDifferences.length === 0) {
        return 'all';
      }
      if (resolvedDifferences.length > 0 && differences.length > 0) {
        return 'partial';
      }
    },
    isOrderDifferenceSolved(differences) {
      if (differences.length === 0) return false;
      const unresolvedDifferences = differences.filter(
        (diff) => diff.quantity?.solved != true && diff.netPrice?.solved != true
      );
      const resolvedDifferences = differences.filter(
        (diff) => diff.quantity?.solved === true || diff.netPrice?.solved === true
      );

      if (unresolvedDifferences.length === 0) {
        return 'all';
      }
      if (resolvedDifferences.length > 0 && differences.length > 0) {
        return 'partial';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-expandable {
  background: #f6f7f9;
}
::v-deep .text-grey {
  color: #9295a5;
}
::v-deep .activity-table {
  th,
  td {
    background: #f6f7f9;
  }

  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }
  }
}
</style>
